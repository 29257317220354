<template>
  <b-card-code
    title="Form Satuan"
  >
    <b-form @submit.prevent="created">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Kelompok"
            label-for="mc-first-name"
          >
            <b-form-input
              v-model="formData.title"
              id="mc-first-name"
              placeholder="Kelompok"
            />
          </b-form-group>
        </b-col>
        
        
        <b-col
          lg="6"
          md="6"
        >
            <b-form-group
                label="Keterangan"
                label-for="desc"
            >
                <b-form-textarea
                v-model="formData.description"
                placeholder="Keterangan"
                rows="2"
                />
        </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ $t('Submit') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadioGroup, BInputGroup, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { getUserData } from '@/auth/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    BInputGroup,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    formData: {
      id: '',
      title: '',
      description: ''
    },
    idInstansi: ''
  }),
  methods: {
    fetchDataGroupUnit(id) { 
      moduleApi.fetchDataGroupUnit(id)
        .then(response => {
            const unit  = response.data.data
            this.formData.id = unit.id
            this.formData.title = unit.title
            this.formData.description = unit.description
        })
        .catch(error => {
          console.log(error)
        })
    },
    created() {
      if (this.formData.id == 'null') {
        moduleApi.createGroupUnit({
            title: this.formData.title,
            description: this.formData.description,
            instansi_id: this.idInstansi
        })
        .then(response => {
            this.$router.replace({name: 'setups-unit'})
        }).catch(error => {
            console.log(error)
        })
      } else {
        moduleApi.updateGroupUnit({
            id: this.formData.id,
            title: this.formData.title,
            description: this.formData.description,
        })
        .then(response => {
            this.$router.replace({name: 'setups-unit'})
        }).catch(error => {
            console.log(error)
        })
      }
    },
  },
  created () {
    const userData = getUserData()
    this.idInstansi = userData.idInstansi
    this.formData.id = this.$route.params.id
    this.fetchDataGroupUnit(this.$route.params.id)
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>
